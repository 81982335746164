<template>
  <div id="tax-page">
    <vs-row>
      <vs-col vs-w="12">
        <div class="vx-card p-6">
          <h3 class="text-xl font-medium mb-3">
            Please check your fees configuration
          </h3>
          <p>
            If any of the fees have been configured incorrectly, you’ll need to
            get in touch with your local Vancy Net team so they can help you
            update these fees.
          </p>

          <vs-table stripe class="mt-5">
            <template slot="thead">
              <vs-th colspan="1"></vs-th>
              <vs-th>
                NOW
              </vs-th>
              <vs-th>
                MOST POPULAR
              </vs-th>
            </template>

            <template>
              <vs-tr>
                <vs-td>
                  Tax & Service
                </vs-td>
                <vs-td>
                  Tax of {{ data.tax }}.00% is included
                </vs-td>
                <vs-td>
                  10% is included
                </vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td>
                  Commission
                </vs-td>
                <vs-td>
                  Service fee of {{ data.service_fee }}.00% is included
                </vs-td>
                <vs-td>

                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import axios from '@/axios'

export default {
  name: 'PropertyTax',

  metaInfo: {
    title: "VAT / Tax / Charge",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },

  data () {
    return {
      data: {
        tax: '',
        service_fee: ''
      }
    }
  },

  methods: {
    getData () {
      axios.get('brand-property')
           .then(({data: res}) => {
             this.data.tax = res.data.tax
             this.data.service_fee = res.data.service_fee
           })
    }
  },

  created () {
    this.getData()
  }
};
</script>

<style>
  .vs-table--not-data {
    display:none !important;
  }
</style>
